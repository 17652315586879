import Cookies from 'js-cookie';

import { kCookiesPolicy, kDomainName } from '../_props/constants.js';

export const opted = {
  in: 'in',
  out: 'out',
  none: 'choose',
};

export const dnt = {
  on: 'dnt',
  one: '1',
  yes: 'yes',
};

export const kTag = 'privacy-policy';

export const cookieInfo = {
  name: 'europarlcookiepolicysagreement',
  optin: '1',
  optout: '0',
  ttl: 365 + 31, // Cookie time to live: 13 months in days
};

export const kStatus = {
  [dnt.on]: 'user has DNT enabled',
  [opted.out]: 'user does NOT want to be tracked.',
  [opted.in]: 'user has opted in, trackers activated.',
  [opted.none]: 'user has not yet expressed choice.',
};

export const normalizedDomain = () =>
  location.hostname.includes(kDomainName)
    ? `.${kDomainName}`
    : location.hostname.replace('www', '');

export const removeATICookies = () => {
  for (const cookie of ATICookies) {
    Cookies.remove(cookie, { sameSite: 'strict' });
  }
};

/* eslint-disable eqeqeq */
export const dntOn = () =>
  navigator.doNotTrack == dnt.yes ||
  navigator.doNotTrack == dnt.one ||
  window.doNotTrack == dnt.one;
/* eslint-enable eqeqeq */

export const ATICookies = [
  'atidvisitor',
  'atreman',
  'atredir',
  'atsession',
  'atuserid',
  'attvtreman',
  'attvtsession',
  'atwebosession',
];

export const setCookie = (value) => {
  // The value of the cookie should be :
  // 1 : the visitor opted in
  // 0 : the visitor opted out
  Cookies.set(cookieInfo.name, value, {
    expires: cookieInfo.ttl,
    domain: normalizedDomain(),
    sameSite: 'strict',
  });
};

export const optInFx = () => {
  setCookie(cookieInfo.optin);
};

export const optOutFx = () => {
  setCookie(cookieInfo.optout);
  cleanupTrackers();
};

export const optedIn = () => Cookies.get(cookieInfo.name) === cookieInfo.optin;

export const optedOut = () =>
  Cookies.get(cookieInfo.name) === cookieInfo.optout;

export const consentStatus = () => {
  const status = dntOn()
    ? dnt.on
    : optedIn()
    ? opted.in
    : optedOut()
    ? opted.out
    : opted.none;
  console.log(`Cookie consent status: ${kStatus[status]}`);
  return status;
};

export const cleanupTrackers = () => {
  // removeScript(trackerUrl(kTag));
  removeATICookies();
};

export const loadTrackers = () => {
  // injectTracker(kTag, 'WEBA');
};

export const trackerModuleIdFromUrl = (url) =>
  url.split('/').pop().split('.').shift();

export const logDNTStatus = () => {
  if (window.doNotTrack || navigator.doNotTrack || navigator.msDoNotTrack) {
    if (dntOn()) {
      console.log('DNT enabled');
    } else {
      console.log('DNT disabled');
    }
  } else {
    console.log('DNT not supported');
  }
};

export const shouldDisplayPopup = (currentPage) =>
  consentStatus() === opted.none && currentPage !== kCookiesPolicy;

export const shouldLoadWebAnalytics = () => consentStatus() === opted.in;
