'use client';

import { useEffect, useRef } from 'react';

import '../../public/assets/css/privacy-policy.css';

import {
  kCookiesInventory,
  kCookiesPolicy,
  kDataProtectionNotice,
  kLoadWebAnalytics,
  kDoNotLoadWebAnalytics,
  kDomainName,
} from '../_props/constants.js';

import { optInFx, optOutFx } from './cookies.js';
import WebAnalytics from './webanalytics.js';

export const url = (lang, slug) => `https://${kDomainName}/${lang}/${slug}/`;

export const Link = ({ url, label }) => (
  <a
    href={url}
    style={{ textDecoration: 'underline', color: 'inherit' }}
    className="cc-link-default">
    {label}
  </a>
);

export const Button = ({ label, handler }) => (
  <button className="epjs_agree" type="button" onClick={handler}>
    <span>{label}</span>
  </button>
);

export const kTransitionEnd = 'transitionend';
export const kDisplayMarker = 'epjs_displayed';

export const Popup = ({
  showPopup,
  lang,
  title,
  body,
  textBottom1,
  textBottom2,
  textBottom3,
  accept,
  refuse,
  linkLabels,
  handleRefuse,
  handleAccept,
  popupRef,
}) => {
  const [introduction, description] = body;

  return showPopup ? (
    <section
      ref={popupRef}
      className="epjs_cookiepolicy"
      id="cookie-policy"
      aria-label={title}>
      <div className="cookie-consent-popup-container">
        <div className="cookie-consent-popup-wrapper">
          <div className="epjs_text" tabIndex="-1">
            <div id="cookie-policy-description-top">
              <p>{introduction}</p>
              <p>{description}</p>
            </div>{' '}
            <div id="cookie-policy-description-bottom">
              {textBottom1}
              <Link
                url={url(lang, kDataProtectionNotice)}
                label={linkLabels[kDataProtectionNotice].toLowerCase()}
              />
              {textBottom2}
              <Link
                url={url(lang, kCookiesPolicy)}
                label={linkLabels[kCookiesPolicy].toLowerCase()}
              />
              {textBottom3}
              <Link
                url={url(lang, kCookiesInventory)}
                label={linkLabels[kCookiesInventory].toLowerCase()}
              />
            </div>
          </div>
          <div className="epjs_buttons">
            <Button label={refuse} handler={handleRefuse} />
            <Button label={accept} handler={handleAccept} />
          </div>
        </div>
      </div>
    </section>
  ) : null;
};

export const PopupHandler = (props) => {
  const { cleanup, showPopup, loadWebAnalytics, setLoadWebAnalytics } = props;
  const popupRef = useRef(null);

  useEffect(() => {
    const popup = popupRef.current;
    setTimeout(() => {
      if (showPopup) {
        popup.classList.add(kDisplayMarker);
      }
    }, 250);

    return () => popup && popup.removeEventListener(kTransitionEnd, cleanup);
  }, [showPopup, cleanup]);

  const handleClick = (canLoadWebAnalytics) => (_) => {
    if (canLoadWebAnalytics) {
      optInFx();
    } else {
      optOutFx();
    }

    setLoadWebAnalytics(canLoadWebAnalytics);

    popupRef.current.addEventListener(kTransitionEnd, cleanup);
    popupRef.current.classList.remove(kDisplayMarker);
  };

  return (
    <>
      <WebAnalytics load={loadWebAnalytics} />
      <Popup
        {...props}
        {...{
          popupRef,
          showPopup,
          handleAccept: handleClick(kLoadWebAnalytics),
          handleRefuse: handleClick(kDoNotLoadWebAnalytics),
        }}
      />
    </>
  );
};

export default PopupHandler;
