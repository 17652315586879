import {
  languageCodes,
  languagesByCountry,
  xExceptions,
  xAccount,
} from '@ee24/data/constants';

export const kHewFAQVideos = {
  1: 'video03',
  2: 'video02',
  3: 'video04',
};

export const kAtEFAQVideos = {
  12: 'video05',
};

// https://developer.mozilla.org/en-US/docs/Learn/Forms/Form_validation
export const kEmailRegExp =
  /^[\w.!#$%&'*+/=?^_`{|}~-]+@[.a-zA-Z\d-]+\.[a-zA-Z\d-]{2,}/;

// export const kDomainName = 'staging-ee24-hub.livepreview.website';
export const kDomainName = 'elections.europa.eu';
export const kUrlPrefix = `https://${kDomainName}`;
export const kElectionResultsDomainURL = `https://results.elections.europa.eu`;

export const kLoadWebAnalytics = true;
export const kDoNotLoadWebAnalytics = false;

export const etrIllustrations = [
  '5-years',
  '6-9-june',
  '720-meps',
  'how-to-vote',
  'law-and-budget',
  'results',
  'voting-box',
];

export const kCountryCodes = new Set(Object.keys(languagesByCountry));

export const xsByY2YsByX = (xs, xsByY) =>
  Object.entries(xsByY).reduce(
    (acc, [y, xs]) => {
      for (const x of xs) {
        acc[x] = [...acc[x], y];
      }

      return acc;
    },
    xs.reduce((acc, x) => {
      acc[x] = [];
      return acc;
    }, {})
  );

export const countriesByLanguage = xsByY2YsByX(
  languageCodes,
  languagesByCountry,
  xExceptions,
  xAccount
);

export * from '@ee24/data/constants';
